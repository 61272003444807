import { Breadcrumbs } from 'src/components';
import { usePathname } from 'next/navigation';
import { Box } from '@mui/material';
import { titleCase } from 'src/utils';
import { useIntegrations } from '../../context';

const SettingsBreadCrumbs = () => {
  const pathname = usePathname();

  const { mappings } = useIntegrations();
  const invalidPaths = ['settings', 'user'];
  const nameMappings = [
    {
      'integration-settings': 'Settings',
    },
  ];
  const generateBreadcrumbPath = (): MedScout.Breadcrumb[] => {
    if (!pathname) return [];
    const asPathParts = pathname
      ?.split('/')
      .filter((v) => !v.includes('[') || !v.includes(']')) // remove query params
      .filter((v) => v.length > 0);

    // remove invalid paths from the list
    const filteredAsPathParts = asPathParts?.filter(
      (part) => !invalidPaths.includes(part)
    );

    const listOfCrumbs = filteredAsPathParts.map((part, index) => {
      const hasAllParts = filteredAsPathParts.length === asPathParts.length;

      // +1 to include the current part, +2 to include the current part and the next part because we still need the full path
      const indexToSlice = hasAllParts ? index + 1 : index + 2;
      const href = `/${asPathParts?.slice(0, indexToSlice).join('/')}`;

      if (!isNaN(Number(part)) && mappings.length > 0) {
        // get the name from the mappings
        const mapping = mappings.find((mapping) => mapping.id === Number(part));

        if (mapping) {
          return {
            name: mapping?.label,
            href,
          };
        }
      }

      // check if part is in the nameMappings and return more user friendly name
      if (nameMappings[0][part]) {
        return {
          name: nameMappings[0][part],
          href,
        };
      }

      // if part is separated by underscore or hyphen, capitalize each word
      if (part.includes('-') || part.includes('_')) {
        return {
          name: titleCase(part),
          href,
        };
      }

      return {
        name: part.charAt(0).toUpperCase() + part.slice(1),
        href,
      };
    });

    return listOfCrumbs;
  };

  // generate name and href for breadcrumbs
  const generateBreadcrumbs = (): MedScout.Breadcrumb[] => {
    if (!pathname) return [];
    // remove last segment of the path
    const asPathParts = pathname
      ?.split('/')
      .filter((v) => v.length > 0)
      .slice(0, -1);

    return mappings.map((crumb, index) => {
      const href = `/${asPathParts?.join('/')}/${crumb.id}`;

      return {
        name: crumb.label,
        href,
      };
    });
  };

  const combinedBreadcrumbs = (): MedScout.Breadcrumb[] => {
    const breadcrumbPath = generateBreadcrumbPath();
    const dropDownOptions = generateBreadcrumbs();

    return breadcrumbPath?.map((crumb) => {
      if (dropDownOptions?.find((option) => option.href === crumb.href)) {
        return {
          ...crumb,
          options: dropDownOptions,
        };
      }

      return crumb;
    });
  };

  const breadcrumbs = combinedBreadcrumbs();

  return (
    <Box sx={{ mt: 1 }}>
      <Breadcrumbs breadcrumbs={breadcrumbs} invalidPaths={invalidPaths} />
    </Box>
  );
};

export default SettingsBreadCrumbs;
