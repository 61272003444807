import { useState } from 'react';
import {
  useFormContext,
  Controller,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';
import { Grid, Input, Box, IconButton, Dropdown, Typography } from '@mui/joy';
import {
  MenuRounded as MenuRoundedIcon,
  DeleteRounded as DeleteRoundedIcon,
} from '@mui/icons-material';
import { DragControls, useDragControls, Reorder } from 'framer-motion';
import { ColorPicker } from 'src/components';
import { StatusButton } from 'src/components/MedRM/MedRMStatus/components';
import { snakeCase } from 'src/utils';

interface Option {
  index: number;
  option: MedScout.Status;
  onDelete?: (index: number) => void;
  controls?: DragControls;
  isLast?: boolean;
  isDraggable?: boolean;
}
interface FormValues {
  options: MedScout.Status[];
}

const StatusAdminItem = ({
  option,
  index,
  onDelete,
  isLast,
  isDraggable,
}: Option) => {
  const controls = useDragControls();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { fields, update }: UseFieldArrayReturn<FormValues> =
    useFieldArray<FormValues>({
      name: 'options',
    });

  const [color, setColor] = useState<string>(option?.color || '#455a64');

  const checkIsDuplicate = (value: string) => {
    // Convert the input value
    const convertedValue = snakeCase(value);

    // Check if the option value matches the converted value
    if (option?.value === convertedValue) return false;

    // Check for duplicates in the statusField options
    const isDuplicate = fields?.some((opt) => opt.value === convertedValue);

    return !!isDuplicate;
  };

  const handleUpdateValue = (value: string) => {
    const convertedValue =
      option?.value !== '' ? option?.value : snakeCase(value);
    option.value = convertedValue;
    option.label = value;
    update(index, option);
  };

  return (
    <Reorder.Item
      key={option?.value}
      value={option}
      style={{
        listStyle: 'none',
        padding: 0,
        margin: 0,
        width: '100%',
        borderBottom: isLast ? 'none' : '1px solid #CDD7E1',
      }}
      dragListener={false}
      dragControls={controls}
    >
      <Controller
        name={`options.${index}.label`}
        control={control}
        rules={{
          validate: (value) => {
            if (!value) return 'Status Label is required';
            if (checkIsDuplicate(value)) return 'Duplicate value';
            return true;
          },
        }}
        render={({ field }) => (
          <Grid
            xs={12}
            onPointerDown={isDraggable ? (e) => controls.start(e) : null}
            sx={{
              padding: '0.5rem 0',
            }}
          >
            <Grid container alignItems="center">
              <Grid
                xs={1}
                md={0.5}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MenuRoundedIcon fontSize="small" />
              </Grid>
              <Grid xs={5} md={5.5} sx={{ padding: '0 0.5rem' }}>
                <Input
                  size="sm"
                  name={`options.${index}.label`}
                  value={`${field.value}`}
                  placeholder="Status Label"
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  onBlur={(e) => {
                    handleUpdateValue(e.target.value);
                  }}
                  error={errors?.options?.[index]?.label ? true : false}
                />
              </Grid>
              <Grid xs={2} sx={{ padding: '0 0.5rem' }}>
                <Dropdown>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      width: '100%',
                    }}
                  >
                    <StatusButton
                      status={{
                        label: field.value,
                        color: color,
                      }}
                      loading={false}
                      onClick={() => {
                        return;
                      }}
                      variant="regular"
                    />
                  </Box>
                </Dropdown>
              </Grid>
              <Grid xs={2} sx={{ padding: '0 0.5rem' }}>
                {errors?.options?.[index]?.label && (
                  <Typography level="title-sm" color="danger">
                    {errors?.options?.[index]?.label?.message}
                  </Typography>
                )}
              </Grid>
              <Grid xs={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '0.5rem',
                    padding: '0 0.5rem',
                  }}
                >
                  <ColorPicker
                    name={`options.${index}.color`}
                    selectedColor={color}
                    setSelectedColor={setColor}
                  />
                  <IconButton
                    size="md"
                    variant="soft"
                    onClick={() => onDelete(index)}
                    color="danger"
                  >
                    <DeleteRoundedIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      />
    </Reorder.Item>
  );
};

export default StatusAdminItem;
