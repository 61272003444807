import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { IconButton } from 'src/components';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import Setting from './Setting';
import { useIntegrations } from '../../context';

interface CRMSettingsDialogProps {
  open: boolean;
  onClose: () => void;
}

const CRMSettingsDialog = ({ open, onClose }: CRMSettingsDialogProps) => {
  const theme = useTheme();
  const { settings } = useIntegrations();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Integration Settings
        <IconButton
          icon={faTimes}
          onClick={onClose}
          size="small"
          style={{
            color: theme.palette.grey[500],
          }}
        />
      </DialogTitle>
      <DialogContent>
        {settings?.map((setting, index) => {
          return <Setting key={index} setting={setting} />;
        })}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.5rem 1rem',
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CRMSettingsDialog;
