import React, { useState } from 'react';
import { Box, Typography, Select, Option } from '@mui/joy';
import { VOLUME_2022_2025 } from 'src/components/VolumeSelectors/constants';

const DateSelect = ({ selectedDate, setSelectedDate }) => {
  const [showErrors, setShowErrors] = useState({ lte: false, gte: false });

  const validateDates = (gteValue, lteValue) => {
    if (!gteValue || !lteValue)
      return setShowErrors({ lte: false, gte: false });
    const isGteValid = parseInt(gteValue) <= parseInt(lteValue);
    const isLteValid = parseInt(lteValue) >= parseInt(gteValue);
    setShowErrors({
      gte: !isGteValid,
      lte: !isLteValid,
    });
  };

  const handleGteChange = (e, newValue) => {
    setSelectedDate({ ...selectedDate, gte: newValue });
    validateDates(newValue, selectedDate.lte);
  };

  const handleLteChange = (e, newValue) => {
    setSelectedDate({ ...selectedDate, lte: newValue });
    validateDates(selectedDate.gte, newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.5rem',
        flex: 0.5,
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0.5rem',
          flex: 0.5,
        }}
      >
        <Typography
          level="body-sm"
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          From
        </Typography>
        <Select
          color={showErrors?.gte ? 'danger' : 'neutral'}
          value={selectedDate?.gte || '2022'}
          sx={{
            width: '100%',
            minWidth: 110,
            borderRadius: '0.5rem',
          }}
          aria-label="Date"
          onChange={handleGteChange}
        >
          {VOLUME_2022_2025?.map((option, index) => (
            <Option key={index} value={option.value} aria-label={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Box>

      <Typography level="body-sm" sx={{ marginTop: '1.5rem' }}>
        -
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0.5rem',
          flex: 0.5,
        }}
      >
        <Typography
          level="body-sm"
          sx={{
            fontSize: '0.875rem',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
        >
          Through
        </Typography>
        <Select
          color={showErrors?.lte ? 'danger' : 'neutral'}
          value={selectedDate?.lte || '2025'}
          sx={{
            width: '100%',
            minWidth: 110,
            borderRadius: '0.5rem',
          }}
          aria-label="Date"
          onChange={handleLteChange}
        >
          {VOLUME_2022_2025?.map((option, index) => (
            <Option key={index} value={option.value} aria-label={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default DateSelect;
