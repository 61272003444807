import { Grid, Typography } from '@mui/joy';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ControlledAutocomplete,
  ControlledInput,
  ControlledSwitch,
} from 'src/components';
import {
  useGetAutopopulateOptions,
  useGetObjectTypeAttributes,
  useGetUserInputOptions,
} from 'src/hooks';
import { ControlledCrmObjectSelect } from 'src/modules/Settings/components/ControlledCrmObjectSelect';
import { verifyOptions } from 'src/modules/Settings/utils';
import { inputKeyMap } from 'src/modules/Settings/constants';
import { useIntegrations } from 'src/modules/Settings/context';

const EditAutopopulate = ({ mapping }) => {
  const {
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { currentOption } = useIntegrations();

  const isThirdParty = mapping?.crm === 'THIRD_PARTY';

  const { data: objectTypeAttributes } = useGetObjectTypeAttributes({
    type: mapping?.medscout_object_type,
    enabled: !!mapping?.medscout_object_type,
  });

  const { data: autopopulateOptions } = useGetAutopopulateOptions({
    mappingId: mapping?.id,
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const { data: userInputOptions } = useGetUserInputOptions({
    mappingId: mapping?.id,
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const attribute_code = watch('attribute_code');
  const selectedAttribute = useMemo(() => {
    return objectTypeAttributes?.find((item) => {
      return item.code === attribute_code;
    });
  }, [attribute_code]);

  const handleValidate = () => {
    const option_type = watch('option_type');
    const crm_api_code = watch('crm_api_code');

    const error = verifyOptions({
      option_type,
      crm_api_code,
      currentOption,
      autopopulateOptions: autopopulateOptions?.results,
      userInputOptions: userInputOptions?.results,
    });

    if (error !== true) {
      setError('crm_api_code', {
        type: 'manual',
        message: error,
      });

      return false;
    }

    if (errors?.crm_api_code) clearErrors('crm_api_code');
    return true;
  };
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <ControlledAutocomplete
          name="attribute_code"
          label="MedScout Property"
          size="small"
          placeholder="Select..."
          options={objectTypeAttributes || []}
          rules={{ required: true }}
        />
      </Grid>
      <Grid xs={12}>
        {isThirdParty ? (
          <ControlledInput name="crm_api_code" label="CRM Property Name" />
        ) : (
          <ControlledCrmObjectSelect
            crmType={mapping?.crm_object_type}
            integration={mapping?.crm}
            name="crm_api_code"
            label="CRM Property Name"
            placeholder="Select..."
            rules={{ required: true, validate: handleValidate }}
            writableOnly={true}
          />
        )}
      </Grid>
      {selectedAttribute?.secondary_input_schemas?.length > 0 &&
        selectedAttribute.secondary_input_schemas?.map((input, index) => {
          const gridWidth =
            selectedAttribute.secondary_input_schemas?.length > 1 ? 6 : 12;

          const InputComponent = inputKeyMap[input.input_type?.toLowerCase()];
          return (
            <Grid xs={gridWidth} key={index}>
              <InputComponent
                name={`secondary_inputs.${input?.code}`}
                label={input?.label}
                options={input?.options}
              />
            </Grid>
          );
        })}
      <Grid xs={12}>
        <ControlledSwitch name="enabled" label="Enabled" value={true} />
      </Grid>
      <Grid xs={12}>
        <ControlledSwitch
          name="use_in_update"
          label="Continuous Sync"
          value={true}
        />
        <Typography
          level="body-xs"
          color="neutral"
          sx={{ paddingLeft: '3rem' }}
        >
          Enabling &apos;Continuous Sync&apos; will allow MedScout to
          automatically update your CRM records with the latest data.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EditAutopopulate;
