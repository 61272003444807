import React from 'react';
import { Grid } from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import {
  ControlledCheckbox,
  ControlledInput,
  ControlledSelect,
} from 'src/components';
import { ControlledCrmObjectSelect } from 'src/modules/Settings/components/ControlledCrmObjectSelect';
import { crmOptions } from 'src/modules/Settings/constants';

const EditCrmLookup = ({ mapping }) => {
  const { watch } = useFormContext();

  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <ControlledInput
          name="label"
          label="Label"
          size="small"
          variant="outlined"
          autoFocus={true}
          rules={{ required: true }}
          placeholder="e.g. MedScout HCP"
        />
      </Grid>
      <Grid xs={12}>
        <ControlledCrmObjectSelect
          name="crm_api_code"
          label="CRM Property Name"
          size="small"
          placeholder="Select..."
          writableOnly
          crmType={mapping?.crm_object_type}
          integration={mapping?.crm}
          rules={{ required: true }}
        />
      </Grid>
      <Grid xs={12}>
        <ControlledSelect
          name="extra.crm_object_type"
          label="CRM Object Type"
          size="small"
          rules={{ required: true }}
          options={crmOptions[mapping?.crm?.toLowerCase()] || []}
        />
      </Grid>
      <Grid xs={6}>
        <ControlledCrmObjectSelect
          name="extra.search_label"
          label="Search Label"
          size="small"
          placeholder="Select..."
          writableOnly={false}
          rules={{ required: true }}
          integration={mapping?.crm}
          crmType={watch('extra.crm_object_type')}
        />
      </Grid>
      <Grid xs={6}>
        <ControlledCrmObjectSelect
          name="extra.value_field"
          label="Value Field"
          size="small"
          placeholder="Select..."
          writableOnly={false}
          rules={{ required: true }}
          integration={mapping?.crm}
          crmType={watch('extra.crm_object_type')}
        />
      </Grid>
      <Grid xs={6}>
        <ControlledCheckbox
          name="extra.required"
          label="Required"
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default EditCrmLookup;
