import React from 'react';
import { Box, Card, Typography } from '@mui/joy';
import { faExternalLinkAlt, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { sentenceCase } from 'src/utils';
import { contentStyles, headerStyle } from './styleConstants';
import InfoUnavailable from './InfoUnavailable';
import { useMemo } from 'react';
import { useProfile } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ProfileCenterOrganizations = () => {
  const { details } = useProfile();
  const { sameTabRouting } = useFlags();

  // Use the newOrgs from the details context
  const newOrgs = useMemo(() => {
    return details?.center?.organizations_v2;
  }, [details]);

  return (
    <Card sx={{ padding: 0 }}>
      <Box sx={headerStyle} aria-label="Organization">
        <Icon icon={faUsers} />
        <Typography
          sx={{
            display: 'inline-block',
            marginLeft: '0.5rem',
          }}
        >
          Organization
        </Typography>
      </Box>
      <Box padding={'0 1rem 1rem'}>
        {Object.keys(newOrgs)?.length > 0 ? (
          Object.keys(newOrgs).map((key, index) => {
            const value = newOrgs[key];

            // If the value is an array, display it as a group
            if (Array.isArray(value)) {
              return (
                <Box
                  key={index}
                  sx={{
                    ...contentStyles(index),
                  }}
                >
                  <Typography level="body-sm">Groups</Typography>
                  {value.map((group, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: 'block !important',
                      }}
                    >
                      <Typography
                        level="body-sm"
                        sx={{
                          color: '#021B9C',
                        }}
                      >
                        <a
                          href={`/profiles/hcp-group/${group.id}`}
                          target={sameTabRouting ? '_self' : '_blank'}
                          rel="noopener noreferrer"
                        >
                          {group.name}{' '}
                          <Icon
                            icon={faExternalLinkAlt}
                            size="xs"
                            style={{ marginLeft: '0.5rem' }}
                          />
                        </a>
                      </Typography>
                      <Typography level="body-sm">
                        {group.description ?? ''}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              );
            }

            // If the value is not an array, display the key-value pair
            return (
              <Box key={index} sx={contentStyles(index)}>
                <Typography level="body-sm">{sentenceCase(key)}</Typography>
                <Typography level="body-sm">{value}</Typography>
              </Box>
            );
          })
        ) : (
          <InfoUnavailable />
        )}
      </Box>
    </Card>
  );
};

export default ProfileCenterOrganizations;
