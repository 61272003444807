import { SideNav } from 'src/components';
import { useAuth } from 'src/context';
import { useRouter } from 'next/router';

const SettingsSidebar = () => {
  const { user } = useAuth();
  const router = useRouter();
  const isManager = user?.permissions?.is_manager || user?.is_superuser;
  const settingsCategories = [
    user?.is_superuser && {
      path: '/settings/integrations',
      label: 'Integrations',
      id: 'integrations',
      active: router.query?.view === 'integrations',
    },
    {
      path: '/settings/user',
      label: 'User Settings',
      id: 'user',
      active: router.query?.view === 'user',
    },
    isManager && {
      path: '/settings/preferences',
      label: 'Preferences',
      id: 'preferences',
      active: router.query?.view === 'preferences',
    },
  ];
  return <SideNav options={settingsCategories} />;
};

export default SettingsSidebar;
