import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLogging, useNotification } from 'src/context';
import { Typography, styled } from '@mui/material';
import {
  useAddMappingOption,
  useGetMappingById,
  useUpdateAutopopulateOption,
  useUpdateUserInputOption,
} from 'src/hooks';
import { MultiStepDialog } from 'src/components';
import { optionTypeMap } from 'src/modules/Settings/constants';
import { Box } from '@mui/joy';
import {
  OptionSelection,
  Autopopulate,
  EditAutopopulate,
  CrmLookup,
  EditCrmLookup,
  UserInput,
  EditUserInput,
} from './Forms';
import { useIntegrations } from 'src/modules/Settings/context';

interface AddEditOptionDialogProps {
  open: boolean;
  onClose: () => void;
  mappingId: string;
  isThirdParty: boolean;
}

const StepHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(0.5),
  fontSize: '0.95rem',
  fontWeight: 600,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const AddSteps = [
  {
    title: 'Option Type',
    description: 'Select a type of option to add.',
  },
  {
    title: 'Property',
    description: 'Select the property to map.',
  },
  {
    title: 'Configure',
    description: 'Select the property to configure.',
  },
  {
    title: 'Review',
    description: 'Review the configuration.',
  },
];

const EditSteps = [
  {
    title: 'Edit Option',
    description: 'Edit the option.',
  },
];

const AddEditOptionDialog = ({
  open,
  onClose,
  mappingId,
  isThirdParty,
}: AddEditOptionDialogProps) => {
  const log = useLogging();
  const { currentOption } = useIntegrations();
  const { setNotification } = useNotification();
  const [activeStep, setActiveStep] = useState(0);

  const isEdit = !!currentOption?.id;
  const steps = isEdit ? EditSteps : AddSteps;
  const defaultValues = isEdit
    ? currentOption
    : {
        option_type: 'auto_populate',
      };

  const methods = useForm({
    values: {
      ...defaultValues,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid },
  } = methods;

  const { data: mapping } = useGetMappingById({
    mappingId,
    enabled: !!mappingId,
  });

  const { mutateAsync: addMappingOption, isLoading: isAddLoading } =
    useAddMappingOption();

  const {
    mutateAsync: updateAutopopulateOption,
    isLoading: updateMappingLoading,
  } = useUpdateAutopopulateOption();

  const {
    mutateAsync: updateUserInputOption,
    isLoading: updateUserInputLoading,
  } = useUpdateUserInputOption();

  const onSubmit = async (data) => {
    if (!data) return;

    const type = watch('option_type');

    try {
      // set value for false checkbox
      if (data.input_type === 'BOOLEAN' && !data.extra?.default_value) {
        data.extra.default_value = false;
      }

      if (isEdit) {
        if (currentOption.option_type === 'user_input') {
          await updateUserInputOption({ data, mappingId });
        } else {
          await updateAutopopulateOption({ data, mappingId });
        }
        log.event(`Updated Option`, {
          ...data,
        });
      } else {
        data = {
          ...data,
          option_type:
            type === 'auto_populate' ? 'auto_populate' : 'user_input',
          input_type:
            type === 'crm_lookup'
              ? 'crm_lookup'
              : data.input_type
              ? data.input_type
              : null,
        };

        await addMappingOption({
          mappingId,
          data: [{ ...data }], // has to be sent as an array, for future reasons
        });
        setNotification({
          title: 'Success',
          message: `Option has been added successfully.`,
          type: 'success',
        });
        log.event(`Added Mapping Option`, {
          ...data,
        });
      }
    } catch (err: any) {
      setNotification({
        title: 'Error',
        message: `An error occurred while adding the option. Please try again.`,
        type: 'error',
      });
      log.exception('Error', {
        tags: {
          source: 'AddOptionForm',
          error: err,
        },
      });
    } finally {
      handleCancel();
    }
  };

  const handleCancel = () => {
    reset();
    setActiveStep(0);
    onClose();
  };

  const option_type = watch('option_type');
  const input_type = watch('input_type');

  const isExtraOptionsEmpty =
    input_type?.toLowerCase() === 'selector' &&
    watch('extra.options')?.length === 0;

  // TODO: revisit this
  const editOptionType =
    currentOption?.option_type === 'auto_populate' ||
    currentOption?.input_type === 'CRM_LOOKUP'
      ? (currentOption?.input_type || 'auto_populate')?.toLowerCase()
      : 'user_input';

  const optionType = !isEdit ? option_type : optionTypeMap[editOptionType];

  const loading =
    isAddLoading || updateMappingLoading || updateUserInputLoading;

  const renderAddForm = () => {
    switch (activeStep) {
      case 0:
        return <OptionSelection isThirdParty={isThirdParty} />;
      case 1:
      case 2:
      case 3: {
        switch (optionType) {
          case 'auto_populate':
            return <Autopopulate activeStep={activeStep} mapping={mapping} />;
          case 'crm_lookup':
            return <CrmLookup activeStep={activeStep} mapping={mapping} />;
          case 'user_input':
            return <UserInput activeStep={activeStep} mapping={mapping} />;
          default:
            return (
              <Box>
                <Typography variant="body1">No form available</Typography>
              </Box>
            );
        }
      }
      default:
        return null;
    }
  };

  const renderEditForm = () => {
    switch (editOptionType) {
      case 'auto_populate':
        return <EditAutopopulate mapping={mapping} />;
      case 'crm_lookup':
        return <EditCrmLookup mapping={mapping} />;
      case 'user_input':
        return <EditUserInput mapping={mapping} />;
      default:
        return null;
    }
  };

  return (
    <MultiStepDialog
      title={`${isEdit ? 'Edit' : 'Add'} Option`}
      steps={steps?.length > 1 ? steps.map((step) => step.title) : []}
      open={open}
      onClose={handleCancel}
      maxWidth={'sm'}
      fullWidth
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      disabled={!isValid || isExtraOptionsEmpty}
    >
      <StepHeader>{`${steps[activeStep]?.description || ''}`}</StepHeader>
      <FormProvider {...methods}>
        <>{isEdit ? renderEditForm() : renderAddForm()}</>
      </FormProvider>
    </MultiStepDialog>
  );
};

export default AddEditOptionDialog;
