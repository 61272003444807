import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface TooltipProps {
  content: string;
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  delay?: number;
}

const ARROW_SIZE = 6; // size of the arrow in pixels

const Tooltip = ({
  content,
  children,
  position = 'top',
  delay = 0,
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState<React.CSSProperties>({});
  const [arrowStyle, setArrowStyle] = useState<React.CSSProperties>({});
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  const showTooltip = () => {
    timeoutRef.current = setTimeout(() => setIsVisible(true), delay);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    if (isVisible && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      let style: React.CSSProperties = {
        position: 'absolute',
      };

      let arrowStyle: React.CSSProperties = {
        position: 'absolute',
        width: `${ARROW_SIZE * 2}px`,
        height: `${ARROW_SIZE * 2}px`,
        background: 'inherit',
        transform: 'rotate(45deg)',
      };

      switch (position) {
        case 'top':
          style = {
            ...style,
            left: rect.left + rect.width / 2 + scrollLeft,
            top: rect.top + scrollTop - 8,
            transform: 'translate(-50%, -100%)',
          };
          arrowStyle = {
            ...arrowStyle,
            bottom: -ARROW_SIZE,
            left: '50%',
            transform: 'translate(-50%, 0) rotate(45deg)',
          };
          break;
        case 'bottom':
          style = {
            ...style,
            left: rect.left + rect.width / 2 + scrollLeft,
            top: rect.bottom + scrollTop + 8,
            transform: 'translate(-50%, 0)',
          };
          arrowStyle = {
            ...arrowStyle,
            top: -ARROW_SIZE,
            left: '50%',
            transform: 'translate(-50%, 0) rotate(45deg)',
          };
          break;
        case 'left':
          style = {
            ...style,
            left: rect.left + scrollLeft - 8,
            top: rect.top + rect.height / 2 + scrollTop,
            transform: 'translate(-100%, -50%)',
          };
          arrowStyle = {
            ...arrowStyle,
            right: -ARROW_SIZE,
            top: '50%',
            transform: 'translate(0, -50%) rotate(45deg)',
          };
          break;
        case 'right':
          style = {
            ...style,
            ...style,
            left: rect.right + scrollLeft + 8,
            top: rect.top + rect.height / 2 + scrollTop,
            transform: 'translate(0, -50%)',
          };
          arrowStyle = {
            ...arrowStyle,
            left: -ARROW_SIZE,
            top: '50%',
            transform: 'translate(0, -50%) rotate(45deg)',
          };
          break;
      }
      setTooltipStyle(style);
      setArrowStyle(arrowStyle);
    }
  }, [isVisible, position]);

  return (
    <div
      ref={triggerRef}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
      className="inline-block"
      data-testid="tooltip"
    >
      {children}
      {isVisible &&
        createPortal(
          <div
            role="tooltip"
            className={`
              fixed z-50 px-2 py-1 text-xs text-gray-800 bg-gray-300 font-medium rounded-lg
              shadow-lg pointer-events-none 
              animate-fade-in w-fit
            `}
            style={tooltipStyle}
            data-testid="tooltip-content"
          >
            {content}
            {/* Add an arrow to the tooltip */}
            <div
              className="absolute bg-gray-900"
              style={arrowStyle}
              aria-hidden="true"
            />
          </div>,
          document.body
        )}
    </div>
  );
};

export default Tooltip;
