import {
  ControlledAutocomplete,
  ControlledCheckbox,
  ControlledInput,
  ControlledRadioCard,
  ControlledRow,
  ControlledSelect,
  ControlledSwitch,
} from 'src/components';
import { ControlledCrmObjectSelect } from './components/ControlledCrmObjectSelect';
import {
  ControlledCheckboxRow,
  DynamicSelectOptionsForm,
  SecondaryInput,
  DateRangeForm,
} from 'src/modules/Settings/components/CRMMappings/Options/components';

export const medscoutOptions = [
  { label: 'Center', value: 'Center' },
  { label: 'Clinic', value: 'Clinic' },
  { label: 'HCP', value: 'HCP' },
];

export const inputOptions = [
  { label: 'Checkbox', value: 'BOOLEAN' },
  { label: 'Number', value: 'INTEGER' },
  { label: 'Select', value: 'SELECTOR' },
  { label: 'Text', value: 'STRING' },
  //TODO: this will require a section to add options, placed in nice to have section.
  // { label: 'AutoComplete', value: 'STRING_WITH_SUGGESTIONS' },
];

// if lookup is true, then the object can be used for lookup mapping
// if mapping is true, then the object can be used for a new mapping
export const crmOptions = {
  salesforce: [
    { label: 'Account', value: 'Account', lookup: true, mapping: true },
    { label: 'Contact', value: 'Contact', lookup: true, mapping: true },
    { label: 'Lead', value: 'Lead', lookup: true, mapping: true },
    { label: 'Opportunity', value: 'Opportunity', lookup: true, mapping: true },
    { label: 'Record Type', value: 'RecordType', lookup: true, mapping: false },
    { label: 'User', value: 'User', lookup: true, mapping: false },
  ],
  hubspot: [
    { label: 'Company', value: 'Company', lookup: true, mapping: true },
    { label: 'Contact', value: 'Contact', lookup: true, mapping: true },
    { label: 'Deal', value: 'Deal', lookup: true, mapping: true },
  ],
  pipedrive: [
    { label: 'Contact', value: 'Contact', lookup: true, mapping: true },
    { label: 'Deal', value: 'Deal', lookup: true, mapping: true },
    { label: 'Lead', value: 'Lead', lookup: true, mapping: true },
    { label: 'People', value: 'People', lookup: true, mapping: true },
  ],
  microsoftdynamics: [
    { label: 'Account', value: 'account', lookup: true, mapping: true },
    { label: 'Contact', value: 'contact', lookup: true, mapping: true },
    { label: 'Lead', value: 'lead', lookup: true, mapping: true },
    { label: 'Opportunity', value: 'opportunity', lookup: true, mapping: true },
  ],
  zohocrm: [
    { label: 'Accounts', value: 'Accounts', lookup: true, mapping: true },
    { label: 'Contacts', value: 'Contacts', lookup: true, mapping: true },
    { label: 'Deals', value: 'Deals', lookup: true, mapping: true },
    { label: 'Leads', value: 'Leads', lookup: true, mapping: true },
  ],
};

export const optionTypeMap = {
  crm_lookup: 'user_input_lookup',
  user_input: 'user_input_custom',
};

export const inputKeyMap = {
  autocomplete: ControlledAutocomplete,
  checkbox: ControlledCheckbox,
  number: ControlledInput,
  integer: ControlledInput,
  radio: ControlledRadioCard,
  row: ControlledRow,
  selector: ControlledSelect,
  string: ControlledInput,
  key_value: DynamicSelectOptionsForm,
  switch: ControlledSwitch,
  checkbox_group: ControlledCheckboxRow,
  secondary_input: SecondaryInput,
  crm_select: ControlledCrmObjectSelect,
  boolean: ControlledCheckbox,
  date_range: DateRangeForm,
};
