import { Grid, Typography } from '@mui/joy';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRouter } from 'next/router';
import { useLogging, useNotification } from 'src/context';
import { useStartOneTimeSync } from 'src/hooks';
import { sentenceCase, titleCase } from 'src/utils';
import SyncHistoryTable from './SyncHistoryTable';
import { useIntegrations } from 'src/modules/Settings/context';

const OneTimeSyncSection = () => {
  const log = useLogging();
  const router = useRouter();
  const { id } = router.query as {
    id: string;
  };
  const { setNotification } = useNotification();

  const { mappings } = useIntegrations();
  const currentMapping = mappings.find(
    (mapping) => mapping.id.toString() === id
  );
  const isThirdParty = currentMapping?.crm === 'THIRD_PARTY';
  const crmName = titleCase(currentMapping?.crm);
  const crmObjType = currentMapping?.crm_object_type;
  const msObjType =
    currentMapping?.medscout_object_type === 'HCP'
      ? currentMapping?.medscout_object_type
      : sentenceCase(currentMapping?.medscout_object_type);

  const { mutateAsync: startOneTimeSync, isLoading } = useStartOneTimeSync();

  const onOneTimeSync = async (direction: string) => {
    if (!id) return;
    try {
      await startOneTimeSync({ mappingId: id, direction });
      setNotification({
        title: 'CRM Sync Started',
        message: 'Sync started successfully, please check back later.',
        type: 'success',
      });
      log.event(`Started One Time Sync`, {
        mappingId: id,
      });
    } catch (err: any) {
      setNotification({
        title: 'Sync Failure',
        message: err?.cause?.response?.data?.error_message || err.message,
        type: 'error',
      });
      log.exception(`Error starting one time sync: ${err}`);
    }
  };

  const handleOneTimePull = () => {
    onOneTimeSync('pull');
  };

  const handleOneTimePush = () => {
    onOneTimeSync('push');
  };

  return (
    <>
      <Typography
        sx={{
          mt: 5,
          mb: 2,
          pt: 2,
          borderTop: `1px solid ${grey[200]}`,
        }}
        level="h3"
      >
        One Time Data Sync
      </Typography>
      <Grid container columns={2} columnGap={12}>
        {!isThirdParty && (
          <Grid width={'33rem'}>
            <Typography sx={{ pt: 2 }} level="h4">
              Pull {crmName} Data
            </Typography>
            <Typography
              sx={{
                mt: 1,
                mb: 1,
              }}
              level="body-sm"
            >
              Link existing {crmName} {currentMapping?.crm_object_type}s to
              MedScout {msObjType}s.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mb: 2, mt: 1 }}
              onClick={handleOneTimePull}
              disabled={isLoading}
            >
              Pull Data
            </Button>
            <SyncHistoryTable direction={'pull'} />
          </Grid>
        )}
        <Grid width={'33rem'}>
          <Typography sx={{ pt: 2 }} level="h4">
            Push {crmName} Data
          </Typography>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
            }}
            level="body-sm"
          >
            Push new MedScout {msObjType} data to your {crmName} {crmObjType}s
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mb: 2, mt: 1 }}
            onClick={handleOneTimePush}
            disabled={isLoading}
          >
            Push Data
          </Button>
          <SyncHistoryTable direction={'push'} />
        </Grid>
      </Grid>
    </>
  );
};

export default OneTimeSyncSection;
