import React from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
interface CheckboxProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'default' | 'primary' | 'secondary';
}

const Checkbox = ({
  checked,
  onChange,
  label,
  size = 'md',
  variant = 'default',
}: CheckboxProps) => {
  const sizes = {
    sm: 'w-4 h-4 text-xs',
    md: 'w-4 h-4 text-sm',
    lg: 'w-6 h-6 text-base',
  };

  const labelSizes = {
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
  };

  const variants = {
    default: 'border-neutral-300 bg-neutral-100',
    primary: 'border-blue-900 bg-blue-900',
    secondary: 'border-gray-600 bg-gray-600',
  };

  const checkmarkVariants = {
    default: 'text-neutral-800',
    primary: 'text-white',
    secondary: 'text-white',
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const syntheticEvent = {
      target: {
        checked: !checked,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    onChange(syntheticEvent);
  };

  return (
    <label
      className="flex items-center cursor-pointer gap-2"
      onClick={handleClick}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <div
        className={`${
          sizes[size]
        } flex items-center justify-center border-2 rounded-sm transition-all 
          ${checked ? variants[variant] : 'border-gray-400'}
        `}
      >
        {checked && (
          <Icon
            icon={faCheck}
            className={`font-bold ${checkmarkVariants[variant]}`}
            style={{
              zIndex: 9999,
            }}
          />
        )}
      </div>
      {label && (
        <span className={`text-gray-800 select-none ${labelSizes[size]}`}>
          {label}
        </span>
      )}
    </label>
  );
};

export default Checkbox;
