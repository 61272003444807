import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { Toggle } from 'src/components';
import { faUserDoctor, faHospital } from '@fortawesome/pro-solid-svg-icons';
import { useProspectSearch, useLogging, useAuth } from 'src/context';
import { useCompany } from 'src/hooks';
const ResultTypeSelector = ({ legacy = false }) => {
  const { user } = useAuth();
  const log = useLogging();
  const { prospectType, setProspectType, prospectMode, setProspectMode } =
    useProspectSearch();

  const [toggleOrientation, setToggleOrientation] = useState<'left' | 'right'>(
    'left'
  );
  // const toggleOrientation = prospectType === 'C' ? 'right' : 'left';

  const { data: company, isLoading: isCompanyLoading } = useCompany(
    user?.company?.id?.toString()
  );

  useEffect(() => {
    if (company) {
      setToggleOrientation(
        company.discovery_default_type === 'C' ? 'right' : 'left'
      );

      setProspectMode(company.discovery_default_mode);
      setProspectType(company.discovery_default_type);
    }
  }, [company]);

  useEffect(() => {
    if (toggleOrientation === 'right' && prospectMode === 'receiving') {
      setProspectMode('treating');
    }
  }, [toggleOrientation]);

  const handleToggleChange = (key, value) => {
    if (key === 'type' && value === 'C') {
      setProspectMode('treating');
      setProspectType('C');
      setToggleOrientation('right');
    } else if (key === 'type' && value === 'HCP') {
      setProspectType('HCP');
      setToggleOrientation('left');
    }

    log.event(`Toggle Changed: ${value === 'C' ? 'Centers' : 'HCP'}`);
  };

  return (
    <Toggle
      leftIcon={faUserDoctor}
      rightIcon={faHospital}
      leftLabel="Providers"
      rightLabel="Sites"
      onLeft={() => {
        handleToggleChange('type', 'HCP');
      }}
      onRight={() => {
        handleToggleChange('type', 'C');
      }}
      status={toggleOrientation}
      showLabels
      slots={
        !legacy
          ? {
              action: () => null,
              thumb: ({ sx, ...props }) => (
                <Select
                  value={prospectMode || 'treating'}
                  size="small"
                  onChange={(e) => {
                    log.event('Mode Changed', {
                      mode: e.target.value,
                    });
                    setProspectMode(e.target.value as typeof prospectMode);
                  }}
                  disabled={prospectType === 'C'}
                  sx={{
                    borderRadius: '1rem',
                    '--Switch-thumbHeight': '1.6rem',
                    '--Switch-thumbWidth': '3.5rem',
                    '--mui-palette-text-disabled': 'white',
                    '& .MuiSelect-select': {
                      width: 'var(--Switch-thumbWidth)',
                      fontSize: '0.8rem',
                      fontWeight: 400,
                      // padding: '0.15rem 0.75rem',
                      color: 'white',
                    },
                    '&.Mui-disabled .MuiSelect-icon': {
                      display: 'none',
                    },
                    '& .MuiSelect-icon': {
                      color: 'white',
                    },
                  }}
                  {...props}
                >
                  <MenuItem value="treating">Treating</MenuItem>
                  <MenuItem value="sending">Referring</MenuItem>
                </Select>
              ),
            }
          : {}
      }
      sx={
        !legacy
          ? {
              width: '8rem',
              '& .MuiSwitch-thumb': {
                transform: 'translate(0, 0)',
              },
              '&.Mui-checked .MuiSwitch-thumb': {
                transform: 'translate(20px, 0)',
              },
            }
          : {}
      }
    />
  );
};

export default ResultTypeSelector;
