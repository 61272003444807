import { Box, Grid } from '@mui/joy';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ControlledCheckbox,
  ControlledInput,
  ControlledJoyCheckbox,
  ControlledRow,
  ControlledSelect,
} from 'src/components';
import { ControlledCrmObjectSelect } from 'src/modules/Settings/components/ControlledCrmObjectSelect';
import { inputOptions } from 'src/modules/Settings/constants';
import { DynamicSelectOptionsForm } from 'src/modules/Settings/components/CRMMappings/Options/components';
import { useGetAutopopulateOptions, useGetUserInputOptions } from 'src/hooks';
import { verifyOptions } from 'src/modules/Settings/utils';
import { useIntegrations } from 'src/modules/Settings/context';

const UserInput = ({ activeStep, mapping }) => {
  const {
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { currentOption } = useIntegrations();

  const isThirdParty = mapping?.crm === 'THIRD_PARTY';

  const { data: autopopulateOptions } = useGetAutopopulateOptions({
    mappingId: mapping?.id,
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const { data: userInputOptions } = useGetUserInputOptions({
    mappingId: mapping?.id,
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const handleValidate = () => {
    const option_type = watch('option_type');
    const crm_api_code = watch('crm_api_code');

    const error = verifyOptions({
      option_type,
      crm_api_code,
      currentOption,
      autopopulateOptions: autopopulateOptions?.results,
      userInputOptions: userInputOptions?.results,
    });

    if (error !== true) {
      setError('crm_api_code', {
        type: 'manual',
        message: error,
      });

      return false;
    }

    if (errors?.crm_api_code) clearErrors('crm_api_code');
    return true;
  };
  const renderSubComponent = () => {
    if (!watch('input_type')) return null;
    const inputType = watch('input_type');
    switch (inputType) {
      case 'BOOLEAN': {
        if (activeStep === 3) {
          return (
            <ControlledRow name="extra.default_value" label="Default Value" />
          );
        } else {
          return (
            <ControlledCheckbox
              name="extra.default_value"
              label="Default Value"
              size="small"
              rules={{ required: false }}
            />
          );
        }
      }
      case 'SELECTOR': {
        if (activeStep === 3) {
          return <ControlledRow name="extra.options" label="Options" />;
        } else {
          return (
            <DynamicSelectOptionsForm
              name="extra.options"
              required={true}
              rules={{ required: true }}
            />
          );
        }
      }
      case 'INTEGER': {
        if (activeStep === 3) {
          return (
            <ControlledRow name="extra.default_value" label="Default Value" />
          );
        } else {
          return (
            <ControlledInput
              name="extra.default_value"
              label="Default Value"
              size="small"
              rules={{ required: false }}
              type="number"
            />
          );
        }
      }
      case 'STRING': {
        if (activeStep === 3) {
          return (
            <ControlledRow name="extra.default_value" label="Default Value" />
          );
        } else {
          return (
            <ControlledInput
              name="extra.default_value"
              label="Default Value"
              size="small"
              rules={{ required: false }}
            />
          );
        }
      }
    }
  };

  switch (activeStep) {
    case 1: {
      return (
        <Box>
          {isThirdParty ? (
            <ControlledInput
              name="crm_api_code"
              label="CRM Property Name"
              rules={{ required: true, validate: handleValidate }}
            />
          ) : (
            <ControlledCrmObjectSelect
              name="crm_api_code"
              label="CRM Property Name"
              size="small"
              placeholder="Select..."
              writableOnly
              crmType={mapping?.crm_object_type}
              integration={mapping?.crm}
              rules={{ required: true, validate: handleValidate }}
            />
          )}
        </Box>
      );
    }
    case 2: {
      return (
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ControlledSelect
              name="input_type"
              label="Input Type"
              size="small"
              rules={{ required: true }}
              options={inputOptions}
            />
          </Grid>
          <Grid xs={12}>{renderSubComponent()}</Grid>
        </Grid>
      );
    }
    case 3: {
      return (
        <Grid container spacing={1}>
          <Grid xs={12}>
            <ControlledInput
              name="label"
              label="Label"
              size="small"
              variant="outlined"
              autoFocus={true}
              rules={{ required: true }}
              placeholder="e.g. MedScout HCP"
            />
          </Grid>
          <Grid xs={12}>
            <ControlledRow name="option_type" label="Option Type" />
          </Grid>
          <Grid xs={12}>
            <ControlledRow name="crm_api_code" label="CRM Property Name" />
          </Grid>
          <Grid xs={12}>
            <ControlledRow name="input_type" label="Input Type" />
          </Grid>
          <Grid xs={12}>{renderSubComponent()}</Grid>
          <Grid xs={12}>
            <ControlledJoyCheckbox
              size="sm"
              name="extra.required"
              label="Required"
            />
          </Grid>
          <Grid xs={12}>
            <ControlledJoyCheckbox size="sm" name="hidden" label="Hidden" />
          </Grid>
        </Grid>
      );
    }
    default:
      return null;
  }
};

export default UserInput;
