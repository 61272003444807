import { useState } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
} from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faEllipsis,
  faTrash,
  faPencil,
} from '@fortawesome/pro-solid-svg-icons';
import { grey, red } from '@mui/material/colors';
import {
  useDeleteAutopopulateOption,
  useDeleteUserInputOption,
} from 'src/hooks';
import { useIntegrationLayout } from '../../context';
import { useLogging } from 'src/context';
import { useRouter } from 'next/router';
import { useIntegrations } from '../../context';

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isDelete',
})<{ isDelete: boolean }>(({ theme, isDelete }) => ({
  color: grey[600],
  display: 'flex',
  alignItems: 'center',
  gap: '0.4rem',
  fontSize: '0.95rem',
  '&:hover': { color: isDelete ? red[900] : 'inherit' },
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
}));

const OptionSettingsCell = ({ row }) => {
  const router = useRouter();
  const log = useLogging();
  const { id } = router.query;
  const { setShowOptionsDialog } = useIntegrationLayout();
  const { setCurrentOption } = useIntegrations();

  const menuOptions = [
    {
      label: 'Edit',
      icon: faPencil,
      onClick: () => {
        setAnchorEl(null);
        setCurrentOption(row);
        setShowOptionsDialog(true);
      },
    },
    {
      label: 'Delete',
      icon: faTrash,
      onClick: () => {
        setAnchorEl(null);
        onDelete(row);
      },
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mutateAsync: deleteAutopopulateOption } =
    useDeleteAutopopulateOption();
  const { mutateAsync: deleteUserInputOption } = useDeleteUserInputOption();

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  async function onDelete(data: any) {
    if (!data) return;
    if (
      confirm(`Are you sure you want to delete option ${data.attribute_code}?`)
    ) {
      try {
        if (data.option_type === 'auto_populate') {
          await deleteAutopopulateOption({ data, mappingId: id?.toString() });
        } else if (data.option_type === 'user_input') {
          await deleteUserInputOption({ data, mappingId: id?.toString() });
        }

        log.event(`Deleted Option`, {
          ...data,
        });
      } catch (err: any) {
        log.exception(`Error deleting option: ${err}`);
      }
    }
  }

  return (
    <StyledContainer>
      <IconButton
        sx={{ textAlign: 'right', fontSize: '1rem' }}
        onClick={handleClick}
      >
        <Icon icon={faEllipsis} size="sm" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuOptions?.map((option, index) => {
          return (
            <StyledMenuItem
              key={index}
              onClick={option.onClick}
              isDelete={option.label === 'Delete'}
            >
              <Icon icon={option.icon} style={{ width: '.8rem' }} size="sm" />
              <Typography>{option.label}</Typography>
            </StyledMenuItem>
          );
        })}
      </Menu>
    </StyledContainer>
  );
};

export default OptionSettingsCell;
