import { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Grid, Select, Option, Typography } from '@mui/joy';
import DateRangeLabel from './DateRangeLabel';
import { VOLUME_SELECT_OPTIONS } from 'src/components/VolumeSelectors/constants';

const DateRangeForm = ({ ...props }) => {
  const {
    control,
    setValue,
    // formState: { errors },
  } = useFormContext();

  const [error, setError] = useState<string | null>(null);

  const handleValidate = (values) => {
    const isValidLTE = values?.lte && values?.lte >= values?.gte;
    const isValidGTE = values?.gte && values?.gte <= values?.lte;

    if (!isValidGTE || !isValidLTE) {
      setError('Invalid values');
      return false;
    }
    setError(null);
    return true;
  };

  useEffect(() => {
    if (!props?.value) {
      setValue(props?.name, { gte: '2020', lte: '2025' });
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Controller
        name={`${props?.name}`}
        control={control}
        rules={{
          validate: (values) => handleValidate(values),
        }}
        render={({ field }) => (
          <Grid container spacing={1}>
            <Grid xs={6}>
              <DateRangeLabel label="From" tooltipText={props.tooltipText} />
              <Select
                size="sm"
                value={field?.value?.gte}
                onChange={(e, newValue) => {
                  field.onChange({ ...field.value, gte: newValue });
                }}
                slotProps={{
                  listbox: {
                    sx: {
                      zIndex: 9999, // This would not show any other way on the result lists
                    },
                  },
                }}
              >
                {VOLUME_SELECT_OPTIONS?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Grid>
            <Grid xs={6}>
              <DateRangeLabel label="Through" tooltipText={props.tooltipText} />
              <Select
                size="sm"
                value={field?.value?.lte}
                onChange={(e, newValue) =>
                  field.onChange({ ...field.value, lte: newValue })
                }
                slotProps={{
                  listbox: {
                    sx: {
                      zIndex: 9999, // This would not show any other way on the result lists
                    },
                  },
                }}
              >
                {VOLUME_SELECT_OPTIONS?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
      />
      {error && (
        <Typography color="danger" level="body-xs">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DateRangeForm;
