import React from 'react';
import { Box, Typography } from '@mui/material';
import { useGetMappingById, useGetObjectTypeAttributes } from 'src/hooks';
import { useRouter } from 'next/router';

const SecondaryInputCell = ({ row }) => {
  const router = useRouter();
  const { id: mappingId } = router.query;

  const { data: mapping } = useGetMappingById({
    mappingId,
    enabled: !!mappingId,
  });

  const { data: objectTypeAttributes } = useGetObjectTypeAttributes({
    type: mapping?.medscout_object_type,
    enabled: !!mapping?.medscout_object_type,
  });

  // get schema from objectTypeAttributes
  const schema = objectTypeAttributes?.find((item) => {
    return item.code === row.attribute_code;
  });

  // I didn't put this in a separate component because it's only
  // used here and this is already a separate component
  const renderValues = () => {
    if (!schema?.secondary_input_schemas) return null;
    if (!row.secondary_inputs) return null;
    return Object.keys(row.secondary_inputs).map((key) => {
      if (key === 'year_range') {
        return (
          <Typography key={key} variant="body2">
            {row.secondary_inputs[key]?.gte} - {row.secondary_inputs[key]?.lte}
          </Typography>
        );
      } else if (key === 'saved_search') {
        const savedSearch = schema.secondary_input_schemas
          .find((item) => {
            return item.code === key;
          })
          ?.options.find((item) => {
            return item.value === row.secondary_inputs[key];
          });

        return (
          <Typography key={key} variant="body2">
            {savedSearch?.label || savedSearch?.value || 'N/A'}
          </Typography>
        );
      } else {
        return <Typography key={key}>{row.secondary_inputs[key]}</Typography>;
      }
    });
  };

  return <Box>{renderValues()}</Box>;
};

export default SecondaryInputCell;
