import { useState } from 'react';
import { useLogging, useNotification } from 'src/context';
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faEllipsis,
  faTrash,
  faPencil,
  faArrowsRotate,
} from '@fortawesome/pro-solid-svg-icons';
import { grey, red } from '@mui/material/colors';
import { useDeleteMapping, useStartOneTimeSync } from 'src/hooks';
import { useIntegrationLayout } from '../../context';
import { IconButton } from 'src/components';
import styled from '@emotion/styled';
import { useIntegrations } from '../../context';

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isDelete',
})<{ isDelete: boolean }>(({ theme, isDelete }) => ({
  color: grey[600],
  display: 'flex',
  alignItems: 'center',
  gap: '0.4rem',
  fontSize: '0.95rem',
  '&:hover': { color: isDelete ? red[900] : 'inherit' },
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
}));

const SettingsCell = ({ row }) => {
  const log = useLogging();
  const { setNotification } = useNotification();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setCurrentMapping } = useIntegrations();
  const { setShowMappingDialog } = useIntegrationLayout();

  const menuOptions = [
    {
      label: 'Edit',
      icon: faPencil,
      onClick: () => {
        setAnchorEl(null);
        setShowMappingDialog(true);
        setCurrentMapping(row);
      },
    },
    {
      label: 'Sync',
      icon: faArrowsRotate,
      onClick: () => {
        setAnchorEl(null);
        onOneTimeSync();
      },
    },
    {
      label: 'Delete',
      icon: faTrash,
      onClick: () => {
        setAnchorEl(null);
        onDelete(row);
      },
    },
  ];

  const { mutateAsync: deleteMapping } = useDeleteMapping();

  const { mutateAsync: startOneTimeSync, isLoading } = useStartOneTimeSync();

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  async function onOneTimeSync() {
    if (!row) return;
    try {
      await startOneTimeSync({ mappingId: row.id, direction: 'pull' });
      setNotification({
        title: 'CRM Sync Started',
        message: 'Sync started successfully, please check back later.',
        type: 'success',
      });
      log.event(`Started One Time Sync`, {
        ...row,
      });
    } catch (err: any) {
      setNotification({
        title: 'Sync Failure',
        message: err?.cause?.response?.data?.error_message || err.message,
        type: 'error',
      });
      log.exception(`Error starting one time sync: ${err}`);
    }
  }

  async function onDelete(data: any) {
    if (!data) return;
    if (confirm(`Are you sure you want to delete mapping ${data.label}?`)) {
      try {
        await deleteMapping(data.id);
        setNotification({
          title: 'Success',
          message: 'Mapping was deleted successfully.',
          type: 'success',
        });
        log.event(`Deleted Mapping`, {
          ...data,
        });
      } catch (err: any) {
        setNotification({
          title: 'Delete Failure',
          message: err?.cause?.response?.data?.error_message || err.message,
          type: 'error',
        });
        log.exception(`Error deleting mapping: ${err}`);
      }
    }
  }

  return (
    <StyledContainer onClick={(e) => e.stopPropagation()}>
      <IconButton
        icon={faEllipsis}
        sx={{ textAlign: 'right', fontSize: '1rem' }}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuOptions?.map((option, index) => {
          return (
            <StyledMenuItem
              key={index}
              onClick={option.onClick}
              isDelete={option.label === 'Delete'}
              aria-label={`Settings: ${option.label}`}
            >
              {option.label === 'Sync' && isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <Icon
                    icon={option.icon}
                    style={{ width: '.8rem' }}
                    size="sm"
                  />
                  <Typography>{option.label}</Typography>
                </>
              )}
            </StyledMenuItem>
          );
        })}
      </Menu>
    </StyledContainer>
  );
};

export default SettingsCell;
