import React from 'react';
import { variants, sizes } from '../constants';

interface CardProps {
  children: React.ReactNode;
  sx?: React.CSSProperties;
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  className?: string;
}

const Card = ({
  children,
  variant = 'outlined',
  size = 'md',
  className = '',
  ...props
}: CardProps) => {
  return (
    <div
      className={`relative rounded-lg shadow-sm overflow-hidden transition-transform ${variants[variant]} ${sizes[size]} ${className}`}
    >
      {children}
    </div>
  );
};

export default Card;
