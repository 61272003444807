import React from 'react';
import { useGetMappingById, useGetObjectTypeAttributes } from 'src/hooks';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';

const AttributeCodeCell = ({ row }) => {
  const router = useRouter();
  const { id: mappingId } = router.query;

  const { data: mapping } = useGetMappingById({
    mappingId,
    enabled: !!mappingId,
  });

  const { data: objectTypeAttributes } = useGetObjectTypeAttributes({
    type: mapping?.medscout_object_type,
    enabled: !!mapping?.medscout_object_type,
  });

  const option = objectTypeAttributes?.find(
    (item) => item.code === row.attribute_code
  );

  return <Typography variant="body2">{option?.label}</Typography>;
};

export default AttributeCodeCell;
