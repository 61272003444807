import { Container } from '@mui/joy';
import React from 'react';
import { SettingsLayout } from 'src/modules/Settings/components';
import { Typography } from '@mui/joy';
import { DiscoveryPreferences } from './components';

const Preferences = () => {
  return (
    <SettingsLayout>
      <Container className="mt-8" maxWidth="lg">
        <div className="mb-4">
          <Typography
            level="title-lg"
            sx={{
              fontSize: '1.5rem',
            }}
          >
            Preferences
          </Typography>
          <Typography level="body-sm">
            Company preferences that apply to all users.
          </Typography>
        </div>
        <DiscoveryPreferences />
      </Container>
    </SettingsLayout>
  );
};

export default Preferences;
