import React, { useEffect } from 'react';
import { Tooltip } from 'src/components';

interface ButtonToggleProps {
  defaultValue?: string;
  options: { value: string; label: string; disabledReason?: string }[];
  onChange: (value: string) => void;
  isDisabled?: (value: string) => boolean;
}

const ButtonToggle = ({
  options,
  onChange,
  defaultValue,
  isDisabled = () => false,
}: ButtonToggleProps) => {
  const [selected, setSelected] = React.useState<string | null>(null);

  useEffect(() => {
    setSelected(defaultValue || options[0].value || null);
  }, [defaultValue, options]);

  const handleToggle = (value: string) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <div className="flex bg-gray-200 rounded-lg overflow-hidden">
      {options.map((option) => {
        const disabled = isDisabled(option.value);
        const button = (
          <button
            key={option.value}
            className={`relative px-4 py-2 transition-all duration-300 cursor-pointer text-sm text-center min-w-[100px]
                      ${
                        selected === option.value
                          ? 'bg-blue-500 text-white font-semibold hover:bg-blue-600'
                          : !disabled && 'hover:bg-gray-300'
                      }

                      ${
                        disabled
                          ? 'cursor-pointer bg-gray-100 text-gray-400'
                          : ''
                      }
                  `}
            onClick={() => handleToggle(option.value)}
            disabled={disabled}
            data-testid={`button-toggle-${option.value}`}
          >
            {option.label}
            {/**
             * Invisible element that takes up space, keeps the button from
             * flexing when font is semibold
             */}
            <span className="absolute opacity-0 font-semibold">
              {option.label}
            </span>
          </button>
        );

        return disabled && option.disabledReason ? (
          <Tooltip content={option.disabledReason}>{button}</Tooltip>
        ) : (
          button
        );
      })}
    </div>
  );
};

export default ButtonToggle;
