import React from 'react';
import { Grid, Container } from '@mui/material';
import {
  SettingsSidebar,
  SettingsBreadCrumbs,
} from 'src/modules/Settings/components';
import { IntegrationLayoutProvider } from 'src/modules/Settings/context';

const SettingsLayout = ({ children }) => {
  return (
    <IntegrationLayoutProvider>
      <Grid container flexWrap="nowrap" sx={{ height: '100%' }}>
        <Grid item sm={3} md={2}>
          <SettingsSidebar />
        </Grid>
        <Grid item sm={9} md={10} sx={{ overflow: 'auto' }}>
          <Container maxWidth={false}>
            <SettingsBreadCrumbs />
            {children}
          </Container>
        </Grid>
      </Grid>
    </IntegrationLayoutProvider>
  );
};

export default SettingsLayout;
