import { Typography } from '@mui/joy';
import React from 'react';
import {
  ButtonToggle,
  Card,
  CardContent,
  CircularProgress,
} from 'src/components';
import { useAuth, useLogging, useNotification } from 'src/context';
import { useCompany, updateNonMedScoutUserSettings } from 'src/hooks';

const DiscoveryPreferences = () => {
  const { user } = useAuth();
  const log = useLogging();
  const { setNotification } = useNotification();
  const {
    data: company,
    isLoading: isCompanyLoading,
    mutate,
  } = useCompany(user?.company?.id?.toString());

  const [preferences, setPreferences] = React.useState({
    type: 'HCP',
    mode: 'treating',
  });

  React.useEffect(() => {
    if (company) {
      setPreferences({
        type: company.discovery_default_type ?? 'HCP',
        mode: company.discovery_default_mode ?? 'treating',
      });
    }
  }, [company]);

  const checkValidity = (key, value) => {
    // if key === type and value === 'C' then mode can not be 'receiving'
    const isNotValid =
      (key === 'type' && value === 'C' && preferences.mode === 'sending') ||
      (key === 'mode' && value === 'sending' && preferences.type === 'C');

    if (isNotValid) {
      setNotification({
        title: 'Error',
        type: 'error',
        message: 'Referring is not available for Sites',
      });
      setPreferences((prev) => ({
        ...prev,
        [key]: key === 'type' ? 'HCP' : 'treating',
      }));

      return false;
    }

    return true;
  };

  const handlePreferenceChange = async (key, value) => {
    const updatedPreferences = {
      ...preferences,
      [key]: value,
    };
    setPreferences(updatedPreferences);

    if (!checkValidity(key, value)) return;
    const updatePayload = {
      discovery_default_type: updatedPreferences.type,
      discovery_default_mode: updatedPreferences.mode,
    };

    try {
      await updateNonMedScoutUserSettings(
        user?.company?.id?.toString(),
        updatePayload
      );
      mutate(
        {
          ...company,
          ...updatePayload,
        },
        false
      );
      log.event('Company Discovery Preferences Changed', {
        source: 'Company Settings',
        company: user?.company?.id,
        [key]: value,
      });
    } catch (err) {
      setNotification({
        title: 'Error',
        type: 'error',
        message: 'Error updating company settings',
      });
      log.exception('Error updating company settings', err);
    } finally {
      mutate();
    }
  };
  return (
    <Card>
      <CardContent className="relative">
        <div className="p-4 border-b border-gray-200">
          <Typography level="title-md">Discovery Defaults</Typography>
          <Typography level="body-sm">
            Sets the default filters for the discovery page.
          </Typography>
        </div>
        <div className=" flex items-center justify-between p-4 border-b border-gray-200">
          <div>
            <Typography level="title-sm">Providers/Sites</Typography>
            <Typography level="body-xs">
              Sets the default to Provders or Sites for the discovery page.
            </Typography>
          </div>
          <ButtonToggle
            options={[
              { value: 'HCP', label: 'Provider' },
              {
                value: 'C',
                label: 'Site',
                disabledReason: 'Referring is not available for Sites',
              },
            ]}
            onChange={(value) => handlePreferenceChange('type', value)}
            defaultValue={preferences?.type ?? 'HCP'}
            isDisabled={(value) =>
              value === 'C' && preferences.mode === 'sending'
            }
          />
        </div>
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <div>
            <Typography level="title-sm">Treating/Referring</Typography>
            <Typography level="body-xs">
              Sets the default to Treating or Referring for the discovery page.
            </Typography>
          </div>
          <ButtonToggle
            options={[
              { value: 'treating', label: 'Treating' },
              {
                value: 'sending',
                label: 'Referring',
                disabledReason: 'Referring is not available for Sites',
              },
            ]}
            onChange={(value) => handlePreferenceChange('mode', value)}
            defaultValue={preferences?.mode ?? 'treating'}
            isDisabled={(value) =>
              value === 'sending' && preferences.type === 'C'
            }
          />
        </div>
        {isCompanyLoading && (
          <div className="flex justify-center items-center h-full absolute inset-0 bg-white bg-opacity-90 z-10">
            <CircularProgress size="sm" color="neutral" />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default DiscoveryPreferences;
