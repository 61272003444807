import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { getSavedSearchFolders } from 'src/api';
import { QUERY_KEYS } from './constants';
import { useAuth } from 'src/context';

const useGetSavedSearchFolders = () => {
  const { user } = useAuth();
  const [shouldFetch, setShouldFetch] = useState(!!user);

  // Update fetch state based on user presence
  useEffect(() => {
    let timeout;

    if (!user) {
      // Delay fetching when user is not logged in
      timeout = setTimeout(() => {
        setShouldFetch(true);
      }, 5000); // Wait for 5 seconds
    } else {
      // Fetch immediately if user is logged in
      setShouldFetch(true);
    }

    // Cleanup on unmount or when user changes
    return () => clearTimeout(timeout);
  }, [user]);

  // Conditionally fetch data
  return useSWR(
    shouldFetch ? [QUERY_KEYS.SAVED_SEARCH_FOLDERS] : null,
    getSavedSearchFolders
  );
};

export default useGetSavedSearchFolders;
