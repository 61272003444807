import React from 'react';
import { TableRow } from '@mui/material';
import { Box, Typography } from '@mui/joy';
import { TableRowItem } from 'src/components/Tables/components/ActivityTable/components/TableRowItem';
import ActivityTypeLabel from 'src/components/Tables/components/ActivityTable/components/ActivityTypeLabel';
import { daysSince } from 'src/utils';
import { PushToCRMGroup } from 'src/components';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBolt, faLeftFromBracket } from '@fortawesome/pro-solid-svg-icons';

interface RowItemProps {
  act: any;
  isManager: boolean;
  hasStatuses: boolean;
  isVisible: boolean;
  onShowblade: (provider: { providerId: string; type: string }) => void;
}

const ActivityRowItem = ({
  act,
  isManager,
  hasStatuses,
  onShowblade,
  isVisible = false,
}: RowItemProps) => {
  const rowRef = React.useRef(null);

  const [isHovered, setIsHovered] = React.useState(false);
  const [hasRendered, setHasRendered] = React.useState(false); // Track if the row has rendered

  React.useEffect(() => {
    if (!hasRendered) {
      setHasRendered(true);
    }
  }, [isVisible, hasRendered]);

  const onHover = () => {
    setIsHovered(true);
  };

  return (
    <TableRow
      ref={rowRef}
      onMouseEnter={onHover}
      onMouseLeave={() => setIsHovered(false)}
      key={act.id} // Key remains stable
      sx={{
        padding: 0,
        backgroundColor: '#fff',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1rem 15rem 15rem 15rem 15rem 15rem auto',
        alignItems: 'center',
        height: '3.5rem',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.05)',
        },
      }}
    >
      <TableRowItem width="1rem"></TableRowItem>
      <TableRowItem width="15rem">
        <ActivityTypeLabel activityId={act.type} />
      </TableRowItem>
      <TableRowItem width="15rem">
        <Box
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
        >
          <Box>
            <Typography
              sx={{
                color: '#1B5FA3',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '13.5rem',
              }}
            >
              {act.provider.name}
            </Typography>
            <Typography
              level="body-xs"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '13rem',
              }}
            >
              {act.provider.specialty}
            </Typography>
          </Box>
          <button
            style={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              padding: '.5rem',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 'var(--radius-md, 0.5rem)',
              border: '1px solid var(--neutral-outlined-Border, #CDD7E1)',
              background: 'var(--common-white, #FFF)',
              boxShadow:
                '0px 1px 2px 0px rgba(21, 21, 21, 0.08), 0px 2px 4px 0px rgba(21, 21, 21, 0.08)',
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.2s ease-in-out',
            }}
            onClick={() =>
              onShowblade({
                providerId: act?.provider?.id,
                type: act?.provider?.type,
              })
            }
          >
            <Icon
              height="1rem"
              width="1rem"
              icon={faLeftFromBracket}
              color="#636B74"
            />
            <Typography
              sx={{
                color: 'var(--text-icon, #636B74)',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '114%',
                padding: '0 0.25rem',
              }}
            >
              Open
            </Typography>
          </button>
        </Box>
      </TableRowItem>
      {isManager && (
        <TableRowItem width="15rem">
          {`${act.owner.first_name} ${act.owner.last_name}`}
        </TableRowItem>
      )}
      <TableRowItem width="15rem">{daysSince(act.created_at)}</TableRowItem>
      <TableRowItem width="15rem">
        <Box>
          <PushToCRMGroup provider={act?.provider} />
        </Box>
      </TableRowItem>
      <TableRowItem>
        <div
          style={{
            flexGrow: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {act.details}
        </div>
      </TableRowItem>
    </TableRow>
  );
};

export default ActivityRowItem;
