import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from '@mui/joy';
import React, { useMemo } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { sentenceCase } from 'src/utils';
import { useProfile, useSite } from 'src/context';
import { useGetSavedSearchesById } from 'src/hooks';
import { Folder } from '../Folder';
import { SavedSearch } from '../SavedSearch';

interface SectionProps {
  index: number;
  name: string;
  context: string;
  multiple?: boolean;
  section: any;
  searchValue?: string;
  onToggle: (index: number) => void;
  onChange?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    search: any
  ) => void;
}

const Section = ({
  index,
  name,
  context,
  multiple = false,
  section,
  searchValue,
  onToggle,
  onChange,
}: SectionProps) => {
  const { state } = useSite();
  const { details } = useProfile();
  const serializerMap = {
    provider: null,
    site: 'top_providers',
  };

  const { data: savedSearches, isLoading } = useGetSavedSearchesById({
    provider_id: details?.provider_id || null,
    serializer: serializerMap[context],
  });

  const openAccordion = state?.savedSearch?.openAccordion;
  const isOpen =
    openAccordion === index || (searchValue && searchValue?.length > 0);
  const isCompany = name === 'COMPANY';

  const filteredSearches = useMemo(() => {
    if (!savedSearches) return [];
    if (!searchValue || searchValue?.length === 0) return savedSearches;

    return savedSearches.filter((search) => {
      return search.search_name
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase());
    });
  }, [savedSearches, searchValue, isCompany]);

  const nonFolderItems = useMemo(() => {
    if (!filteredSearches) return [];
    // Get all IDs for all items in the section
    const allIdsInFolders = section?.results
      ?.flatMap((folder) => folder?.saved_searches ?? [])
      .join(',');

    return filteredSearches
      ?.filter((search) => {
        const isInFolders = allIdsInFolders?.includes(search.id);
        // If the search is not in any folders, it should be displayed in the section and not in a folder
        return !isInFolders && search.org_saved_search === isCompany;
      })
      .sort((a, b) => {
        // sort numerically by matching_providers
        return b.matching_providers - a.matching_providers;
      });
  }, [isCompany, filteredSearches, section?.results]);

  const folderWithSearches = useMemo(() => {
    if (!filteredSearches) return [];
    return section?.results?.map((folder) => {
      const searches = folder.saved_searches
        ?.map((search) => filteredSearches.find((s) => s.id === Number(search)))
        ?.filter(Boolean); // Remove undefined entries
      return {
        ...folder,
        saved_searches: searches?.sort((a, b) => {
          // Sort numerically by matching_providers
          return b.matching_providers - a.matching_providers;
        }),
      };
    });
  }, [filteredSearches, section?.results]);

  return (
    <Accordion
      sx={{
        borderTop: '1px solid #E0E0E0',
      }}
      expanded={isOpen}
      onChange={(e) => {
        e.stopPropagation();
        onToggle(index);
      }}
    >
      <AccordionSummary
        indicator={null}
        sx={{
          display: 'flex',
          height: '2.5rem',
          alignItems: 'center',
          gap: 'var(--1, 0.5rem)',
          width: '100%',
        }}
        aria-label={`${sentenceCase(name)}`}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 'var(--1, 0.25rem)',
            padding: '0 0.25rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '24px',
              width: '24px',
              flex: '0 0 24px',
            }}
          >
            <Icon
              icon={isOpen ? faChevronDown : faChevronRight}
              style={{
                color: '#636B74',
                padding: 0,
              }}
              size="sm"
            />
          </Box>
          <Typography
            sx={{
              color: 'var(--text-tertiary, #555E68)',
              fontSize: '0.875rem',
              fontWeight: 700,
              lineHeight: '1.243rem' /* 19.88px */,
            }}
          >
            {sentenceCase(name)}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '4rem',
              width: '100%',
            }}
          >
            <CircularProgress size="sm" color="neutral" />
          </Box>
        ) : (
          <Box
            sx={{
              height: '100%',
              padding: 'var(--none, 0px) var(--1, 0.5rem)',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {folderWithSearches?.map((folder, index) => {
              return (
                <Folder
                  key={index}
                  folder={folder}
                  multiple={multiple}
                  context={context}
                  onChange={onChange}
                />
              );
            })}

            {nonFolderItems.map((search, index) => {
              return (
                <SavedSearch
                  key={index}
                  search={search}
                  multiple={multiple}
                  context={context}
                  onChange={onChange}
                />
              );
            })}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Section;
