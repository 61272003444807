import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Switch,
  Box,
  FormControlLabel,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRouter } from 'next/router';
import {
  useGetUserNotificationSettings,
  useUpdateUserNotificationSettings,
} from 'src/hooks';
import { useLogging, useNotification } from 'src/context';

const SWITCH_CONSTANTS = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const NOTIFICATION_OPTIONS = {
  generic_notifications: { label: 'Allow Email Notifications' },
  crm_sync_status_errors: { label: 'CRM Sync Issues' },
};

const EmailSettings = () => {
  const router = useRouter();
  const log = useLogging();
  const { setNotification } = useNotification();

  const { data } = useGetUserNotificationSettings();
  const { mutateAsync: updateUserSettings } =
    useUpdateUserNotificationSettings();

  async function onSettingsToggle({ notificationKey, checked }) {
    try {
      log.event('notificationSettingToggled', {
        checked,
        notificationKey,
      });

      await updateUserSettings({
        notification_settings: {
          ...data,
          [notificationKey]: {
            value: checked
              ? SWITCH_CONSTANTS.ENABLED
              : SWITCH_CONSTANTS.DISABLED,
            extra: {
              reason:
                isFromUnsubscribe && !checked
                  ? 'USER_SETTING_UNSUBSCRIBE'
                  : 'USER_SETTINGS',
            },
          },
        },
      });

      setNotification({
        type: 'success',
        title: 'Success',
        message: 'Your settings have been updated.',
      });
    } catch (err: any) {
      log.exception(`Error updating user settings: ${err.message}`);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'There was an error updating your settings.',
      });
    }
  }

  const isFromUnsubscribe = router?.query?.source === 'unsubscribe';
  return (
    <Container maxWidth={false} sx={{ mt: 3 }} disableGutters>
      <Card>
        <CardHeader title="Email Preferences" sx={{ background: grey[200] }} />
        <CardContent sx={{ pt: 1 }}>
          <Typography
            variant="body1"
            component="p"
            sx={{ fontWeight: 600, fontSize: '0.9rem' }}
          >
            {isFromUnsubscribe
              ? 'Update your settings to unsubscribe from specific types of emails.'
              : 'Select what type of information you would like to receive emails about.'}
          </Typography>
          <Divider sx={{ mt: 1, mb: 1 }} />
          {Object.keys(NOTIFICATION_OPTIONS).map((option) => {
            const notification = data?.[option];
            if (!notification) return null;
            const nonGenericAndDisabled =
              option !== 'generic_notifications' &&
              data.generic_notifications.value !== SWITCH_CONSTANTS.ENABLED;

            return (
              <Box
                key={option}
                sx={{
                  pl: option !== 'generic_notifications' ? 3 : 0,
                }}
              >
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Switch
                      size="small"
                      checked={
                        notification.value === SWITCH_CONSTANTS.ENABLED ||
                        (notification.value === SWITCH_CONSTANTS.DISABLED &&
                          nonGenericAndDisabled)
                      }
                      onChange={(e) =>
                        onSettingsToggle({
                          notificationKey: option,
                          checked: e.target.checked,
                        })
                      }
                      disabled={nonGenericAndDisabled}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{ fontWeight: 500, fontSize: '0.9rem' }}
                    >
                      {NOTIFICATION_OPTIONS[option].label}
                    </Typography>
                  }
                />
              </Box>
            );
          })}
        </CardContent>
      </Card>
    </Container>
  );
};

export default EmailSettings;
