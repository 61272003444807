import { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { ControlledInput } from 'src/components';
import {
  Box,
  styled,
  Typography,
  IconButton,
  Tooltip,
  List,
  ListItem,
  useTheme,
  FormHelperText,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';

const KeyValueHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  marginBottom: theme.spacing(1),
}));

interface DynamicSelectOptionsFormProps {
  name: string;
  required?: boolean;
  rules?: any;
}

const DynamicSelectOptionsForm = ({
  ...props
}: DynamicSelectOptionsFormProps) => {
  const theme = useTheme();
  const {
    control,
    watch,
    formState: { isValid, errors },
    setError,
    clearErrors,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${props?.name}`,
  });

  const required = props?.required || props.rules?.required;
  useEffect(() => {
    if (watch(`${props?.name}`)?.length === 0) {
      append({ value: '', label: '' });
    }
  }, []);

  function checkForDuplicates() {
    // check both value and label for duplicates
    const values = watch(`${props?.name}`).map((item) => item.value);
    const labels = watch(`${props?.name}`).map((item) => item.label);
    // const hasDuplicates = fields.find((field) => {
    //   return (
    //     values.filter((value) => value === field?.value)?.length > 1 ||
    //     labels.filter((label) => label === field?.label)?.length > 1
    //   );
    // });
    const hasDuplicates =
      new Set(values).size !== values.length ||
      new Set(labels).size !== labels.length;

    if (hasDuplicates) {
      setError('options', {
        message: 'Duplicate values/labels are not allowed',
      });
      return false;
    }

    if (errors.options) clearErrors('options');
    return true;
  }

  return (
    <Box>
      <KeyValueHeader>
        <Typography variant="caption" color={grey[500]}>
          Add values to the select field
        </Typography>
        <Tooltip title="Add a Value" placement="bottom" arrow>
          <span>
            <IconButton
              onClick={() => {
                // set the current index to the one just added
                append({ value: '', label: '' });
              }}
              size="small"
              sx={{ color: green[700] }}
              disabled={!isValid}
            >
              <Icon icon={faPlus} />
            </IconButton>
          </span>
        </Tooltip>
      </KeyValueHeader>
      <List>
        {fields?.map((field, index) => (
          <ListItem
            key={index}
            sx={{
              display: 'flex',
              gap: '0.5rem',
              '&:not(:last-child)': {
                marginBottom: 1,
              },
            }}
            disablePadding
          >
            <ControlledInput
              autoFocus
              name={`${props?.name}[${index}].value`}
              placeholder="Value..."
              rules={{
                required: required,
                validate: () => checkForDuplicates(),
              }}
              variant="standard"
              size="small"
              label="Value"
              onBlur={() => {
                const value = watch(`${props?.name}[${index}].value`);
                if (!value) {
                  remove(index);
                }
              }}
            />
            <ControlledInput
              name={`${props?.name}[${index}].label`}
              placeholder="Label..."
              rules={{
                required: required,
                validate: () => checkForDuplicates(),
              }}
              variant="standard"
              size="small"
              label="Label"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (checkForDuplicates()) {
                    append({ value: '', label: '' });
                  }
                }
              }}
              onBlur={() => {
                const value = watch(`${props?.name}[${index}].value`);
                const label = watch(`${props?.name}[${index}].label`);

                if (!label && !value) {
                  remove(index);
                }
              }}
            />
            <Tooltip title="Remove Value" placement="bottom" arrow>
              <IconButton
                onClick={() => {
                  checkForDuplicates();
                  remove(index);
                }}
                sx={{ color: theme.palette.error.main }}
              >
                <Icon icon={faTimes} size="xs" />
              </IconButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
      <FormHelperText error sx={{ ml: 0 }}>
        {!!fields.length && watch(`${props?.name}`)?.length === 0
          ? 'At least one value is required'
          : ''}
        {!!errors['options'] && errors['options']?.message}
      </FormHelperText>
    </Box>
  );
};

export default DynamicSelectOptionsForm;
