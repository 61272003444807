import { Box, Card, Typography } from '@mui/joy';
import { faExternalLinkAlt, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { hasContent } from 'src/utils';
import { contentStyles, headerStyle } from './styleConstants';
import InfoUnavailable from './InfoUnavailable';
import { useProfile } from 'src/context';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ProfileOrganizations = () => {
  const { details } = useProfile();
  const { sameTabRouting } = useFlags();

  const title = `Organization${
    details?.hcp?.hcp_groups?.length === 1 ? '' : 's'
  }`;

  const hcpGroups = details?.hcp?.hcp_groups
    ? [...details.hcp.hcp_groups].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return (
    <Card sx={{ padding: 0 }}>
      <Box sx={headerStyle} aria-label="Organization">
        <Icon icon={faUsers} />
        <Typography
          sx={{
            display: 'inline-block',
            marginLeft: '0.5rem',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box padding={'0 1rem 1rem'}>
        {hasContent(hcpGroups) ? (
          hcpGroups.map((group, i) => {
            return (
              <Box
                key={`${group.id}-${i}`}
                sx={{
                  ...contentStyles(i),
                  display: 'block !important',
                }}
              >
                <Typography
                  level="body-sm"
                  sx={{
                    color: '#021B9C',
                  }}
                >
                  <a
                    href={`/profiles/hcp-group/${group.id}`}
                    target={sameTabRouting ? '_self' : '_blank'}
                    rel="noopener noreferrer"
                  >
                    {group.name} <Icon icon={faExternalLinkAlt} size="xs" />
                  </a>
                </Typography>
                {group.description && (
                  <Typography level="body-xs">{group.description}</Typography>
                )}
              </Box>
            );
          })
        ) : (
          <InfoUnavailable />
        )}
      </Box>
    </Card>
  );
};

export default ProfileOrganizations;
