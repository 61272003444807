import { Box, Grid } from '@mui/joy';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ControlledCheckbox,
  ControlledInput,
  ControlledRow,
  ControlledSelect,
} from 'src/components';
import { useGetAutopopulateOptions, useGetUserInputOptions } from 'src/hooks';
import { ControlledCrmObjectSelect } from 'src/modules/Settings/components/ControlledCrmObjectSelect';
import { crmOptions } from 'src/modules/Settings/constants';
import { useIntegrations } from 'src/modules/Settings/context';
import { verifyOptions } from 'src/modules/Settings/utils';

const CrmLookup = ({ activeStep, mapping }) => {
  const {
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { currentOption } = useIntegrations();

  const { data: autopopulateOptions } = useGetAutopopulateOptions({
    mappingId: mapping?.id,
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const { data: userInputOptions } = useGetUserInputOptions({
    mappingId: mapping?.id,
    sort: 'label',
    order: 'asc',
    page: 0,
    pageSize: 100,
  });

  const handleValidate = () => {
    const option_type = watch('option_type');
    const crm_api_code = watch('crm_api_code');

    const error = verifyOptions({
      option_type,
      crm_api_code,
      currentOption,
      autopopulateOptions: autopopulateOptions?.results,
      userInputOptions: userInputOptions?.results,
    });

    if (error !== true) {
      setError('crm_api_code', {
        type: 'manual',
        message: error,
      });

      return false;
    }

    if (errors?.crm_api_code) clearErrors('crm_api_code');
    return true;
  };

  switch (activeStep) {
    case 1: {
      return (
        <Box>
          <ControlledCrmObjectSelect
            name="crm_api_code"
            label="CRM Property Name"
            size="small"
            placeholder="Select..."
            writableOnly
            crmType={mapping?.crm_object_type}
            integration={mapping?.crm}
            rules={{ required: true, validate: handleValidate }}
          />
        </Box>
      );
    }
    case 2: {
      return (
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ControlledSelect
              name="extra.crm_object_type"
              label="CRM Object Type"
              size="small"
              rules={{ required: true }}
              options={crmOptions[mapping?.crm?.toLowerCase()] || []}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledCrmObjectSelect
              name="extra.search_label"
              label="Search Label"
              size="small"
              placeholder="Select..."
              writableOnly={false}
              rules={{ required: true }}
              integration={mapping?.crm}
              crmType={watch('extra.crm_object_type')}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledCrmObjectSelect
              name="extra.value_field"
              label="Value Field"
              size="small"
              placeholder="Select..."
              writableOnly={false}
              rules={{ required: true }}
              integration={mapping?.crm}
              crmType={watch('extra.crm_object_type')}
            />
          </Grid>
        </Grid>
      );
    }
    case 3: {
      return (
        <Grid container spacing={1}>
          <Grid xs={12}>
            <ControlledInput
              name="label"
              label="Label"
              size="small"
              variant="outlined"
              autoFocus={true}
              rules={{ required: true }}
              placeholder="e.g. MedScout HCP"
            />
          </Grid>
          <Grid xs={12}>
            <ControlledRow name="option_type" label="Option Type" />
          </Grid>
          <Grid xs={12}>
            <ControlledRow name="crm_api_code" label="CRM Property Name" />
          </Grid>
          <Grid xs={12}>
            <ControlledRow
              name="extra.crm_object_type"
              label="CRM Object Type"
            />
          </Grid>
          <Grid xs={12}>
            <ControlledRow
              name="extra.search_label"
              label="Search Label"
              writableOnly={false}
            />
          </Grid>
          <Grid xs={12}>
            <ControlledRow
              name="extra.value_field"
              label="Value Field"
              writableOnly={false}
            />
          </Grid>
          <Grid xs={6}>
            <ControlledCheckbox
              name="extra.required"
              label="Required"
              size="small"
            />
          </Grid>
        </Grid>
      );
    }
    default:
      return null;
  }
};

export default CrmLookup;
