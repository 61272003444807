import { useMemo, useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useLogging } from 'src/context';
import { Button, Typography, Container, Box } from '@mui/material';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { TableColumn } from 'src/modules/Settings/components';
import {
  useGetAutopopulateOptions,
  useGetUserInputOptions,
  useGetMappings,
} from 'src/hooks';
import { useIntegrationLayout } from '../../../context';
import AddEditOptionDialog from './AddEditOptionDialog';
import MappingDefinitionForm from '../MappingDefinitionForm';
import { currentTableStyle } from 'src/styles/CurrentTableStyle';
import { useIntegrations } from 'src/modules/Settings/context';

const Options = () => {
  const log = useLogging();
  const router = useRouter();
  const { id, integration } = router.query as {
    id: string;
    integration: string;
  };
  const { settings, setCurrentOption } = useIntegrations();
  const { showOptionsDialog, setShowOptionsDialog } = useIntegrationLayout();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [userPaginationModel, setUserPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [sort, setSort] = useState({
    field: 'attribute_code',
    sort: 'asc',
  });

  const [userInputSort, setUserInputSort] = useState({
    field: 'label',
    sort: 'asc',
  });

  const { data: allMappings } = useGetMappings({
    page: 0,
    pageSize: 1000,
    sort: 'label',
    order: 'asc',
    crm: integration,
  });

  const {
    data: autoPopulateOptions,
    isLoading: autoPopulateIsLoading,
    isFetching: autoPopulateIsFetching,
  } = useGetAutopopulateOptions({
    mappingId: id?.toString(),
    sort: sort.field,
    order: sort.sort,
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
  });

  const mapping = useMemo(() => {
    return allMappings?.results?.find(
      (mapping) => mapping.id?.toString() === id
    );
  }, [allMappings, id]);

  const isThirdParty = mapping?.crm === 'THIRD_PARTY';

  const {
    data: userInputOptions,
    isLoading: userInputIsLoading,
    isFetching: userInputIsFetching,
  } = useGetUserInputOptions({
    mappingId: id?.toString(),
    sort: userInputSort.field,
    order: userInputSort.sort,
    page: userPaginationModel.page,
    pageSize: userPaginationModel.pageSize,
  });

  const autopopulateColumns: GridColDef[] = [
    TableColumn.attributeCode('attribute_code', 'MedScout Field'),
    TableColumn.crmApiCode('crm_api_code', 'CRM Field'),
    TableColumn.secondaryInput('secondary_input', 'Context'),
    TableColumn.continuousSyncStatus('use_in_update', 'Sync Status'),
    TableColumn.optionSettings('settings', 'Settings'),
  ];

  const userInputColumns: GridColDef[] = [
    TableColumn.optionLabelCell('label', 'Label'),
    TableColumn.crmApiCode('crm_api_code', 'CRM Field'),
    TableColumn.inputType('input_type', 'Input Type'),
    TableColumn.hidden('hidden', 'Hidden'),
    // Extra columns
    TableColumn.required('extra.required', 'Required'),
    !isThirdParty &&
      TableColumn.extraCrmObjectType(
        'extra.crm_object_type',
        'CRM Object Type'
      ),
    !isThirdParty &&
      TableColumn.extraSearchLabel('extra.search_label', 'Search Label'),
    !isThirdParty &&
      TableColumn.extraValueField('extra.value_field', 'Value Field'),
    TableColumn.extraDefaultValue('extra.default_value', 'Default Value'),
    TableColumn.extraOptions('extra.options', 'Options'),
    TableColumn.optionSettings('settings', 'Settings'),
  ].filter(Boolean); //this filter removes any false values

  const isLoading =
    autoPopulateIsLoading ||
    autoPopulateIsFetching ||
    userInputIsLoading ||
    userInputIsFetching;

  const isSettingsEmpty = settings?.some((setting) => !setting?.value);

  useEffect(() => {
    if (!id) return;

    log.event('Viewed Mapping Options', {
      mappingId: id,
    });
  }, [id]);

  const getRowHeight = useCallback(({ model, densityFactor }) => {
    const optionsMultiplier = 24;
    let options = 0;

    if (model.extra.options) {
      options = model.extra.options.length * optionsMultiplier * densityFactor;
    }

    return options || null;
  }, []);

  return (
    <Container maxWidth={false} sx={{ mt: 3, mb: 5 }} disableGutters>
      <MappingDefinitionForm
        mapping={mapping}
        userInputOptions={userInputOptions}
      />

      <Typography
        sx={{
          mt: 1,
          mb: 2,
          fontWeight: 600,
          fontSize: '1.4rem',
        }}
      >
        Mapping Options
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 1 }}
          onClick={() => {
            setShowOptionsDialog(true);
          }}
          disabled={isSettingsEmpty}
        >
          <Icon icon={faPlus} style={{ marginRight: 5 }} />
          Add Option
        </Button>
        {!isLoading && isSettingsEmpty && (
          <Typography
            sx={{
              color: 'error.main',
              fontSize: '0.8rem',
              fontWeight: 600,
            }}
          >
            Please fill out all settings before creating an option
          </Typography>
        )}
      </Box>
      <Typography
        sx={{
          mt: 2,
          mb: 1,
          fontWeight: 600,
          fontSize: '1.2rem',
        }}
      >
        Autopopulate Options
      </Typography>
      <DataGridPremium
        autoHeight
        loading={isLoading}
        rows={autoPopulateOptions?.results || []}
        rowCount={autoPopulateOptions?.count || 0}
        columns={autopopulateColumns}
        sx={{
          ...currentTableStyle,
          border: '1px solid #e0e0e0',
        }}
        disableColumnMenu
        disableMultipleRowSelection
        disableRowSelectionOnClick
        pagination
        pageSizeOptions={[10, 25, 50]}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        onSortModelChange={(model) => {
          if (!model.length)
            return setSort({
              field: 'attribute_code',
              sort: 'asc',
            });
          const { field, sort } = model[0];
          setSort({
            field,
            sort,
          });
        }}
      />
      <Typography
        sx={{
          mt: 2,
          mb: 1,
          fontWeight: 600,
          fontSize: '1.2rem',
        }}
      >
        User Input Options
      </Typography>
      <DataGridPremium
        autoHeight
        loading={isLoading}
        rows={userInputOptions?.results || []}
        rowCount={userInputOptions?.count || 0}
        columns={userInputColumns}
        sx={{
          ...currentTableStyle,
          border: '1px solid #e0e0e0',
        }}
        getRowHeight={getRowHeight}
        disableColumnMenu
        disableMultipleRowSelection
        disableRowSelectionOnClick
        pagination
        pageSizeOptions={[10, 25, 50]}
        paginationMode="server"
        paginationModel={userPaginationModel}
        onPaginationModelChange={setUserPaginationModel}
        sortingMode="server"
        onSortModelChange={(model) => {
          if (!model.length)
            return setUserInputSort({
              field: 'label',
              sort: 'asc',
            });
          const { field, sort } = model[0];
          setSort({
            field,
            sort,
          });
        }}
      />
      <AddEditOptionDialog
        open={showOptionsDialog}
        onClose={() => {
          setCurrentOption(null);
          setShowOptionsDialog(false);
        }}
        mappingId={id?.toString()}
        isThirdParty={isThirdParty}
      />
    </Container>
  );
};

export default Options;
