import React from 'react';
import { useRouter } from 'next/router';
import { useGetCrmObjects } from 'src/hooks';
import { Box } from '@mui/material';
import { useIntegrations } from '../../context';

const CRMApiCode = ({ ...props }) => {
  const { row, field } = props;
  const router = useRouter();
  const { integration, id } = router.query as {
    integration: string;
    id: string;
  };
  const { mappings } = useIntegrations();

  // Get mapping from id
  const mapping = mappings.find((mapping) => mapping.id === Number(id));

  const isThirdParty = mapping?.crm === 'THIRD_PARTY';
  // Get object type and api code from field value
  const objectTypeMap = {
    crm_api_code: {
      objectType: mapping?.crm_object_type,
      api_code: row.crm_api_code,
    },
    'extra.search_label': {
      objectType: row.extra?.crm_object_type,
      api_code: row.extra?.search_label,
    },
    'extra.value_field': {
      objectType: row.extra?.crm_object_type,
      api_code: row.extra?.value_field,
    },
  };

  // Get crm objects based on object type
  const { data: crmObjects } = useGetCrmObjects({
    crmType: objectTypeMap[field]?.objectType,
    crm: integration,
    writable_only: false,
    isThirdParty,
  });

  // Find crm object based on api code
  const crmObject = crmObjects?.find(
    (crmObject) => crmObject.code === objectTypeMap[field]?.api_code
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {isThirdParty ? row?.crm_api_code : crmObject?.label}
    </Box>
  );
};

export default CRMApiCode;
