import React, { useCallback, useRef } from 'react';
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { Box, Dropdown, Menu, Typography } from '@mui/joy';
import { useOnClickOutside } from 'src/hooks';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { MarkerCircle } from '../MarkerCircle';
import { useFlags } from 'launchdarkly-react-client-sdk';

type TreeMarkerProps = {
  feature: any;
  position: any;
  featureId: number;
  size: number;
  sizeAsText: string;
  isHovered?: boolean;
  onMarkerClick?: (marker: any, featureId: number) => void;
  onMouseOver?: (clusterId: number) => void;
  onMouseOut?: () => void;
  addToList?: (details: any) => void;
};

const FeatureMarker = ({
  feature,
  position,
  featureId,
  size,
  sizeAsText,
  isHovered = false,
  onMarkerClick,
  onMouseOut,
  onMouseOver,
  addToList,
}: TreeMarkerProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [markerRef, marker] = useAdvancedMarkerRef();
  const { sameTabRouting } = useFlags();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isPoint =
    feature.geometry?.type === 'Point' &&
    feature?.details &&
    !feature.properties.count;

  const handleClick = useCallback(
    () => onMarkerClick && onMarkerClick(marker!, featureId),
    [onMarkerClick, marker, featureId]
  );

  const handleHoverMenu = (event: React.MouseEvent<HTMLElement>, featureId) => {
    onMouseOver(featureId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onMouseOut();
    setAnchorEl(null);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <AdvancedMarker
      ref={markerRef}
      zIndex={size}
      position={position}
      onClick={() => {
        handleClick();
      }}
      style={{
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '10px', // Adjust based on gap size
          top: 0, // Position between marker and menu
          left: 0,
          background: 'transparent',
          pointerEvents: 'auto', // Ensures mouseover is detected
        }}
        onMouseOver={(e) => handleHoverMenu(e, featureId)}
      />
      <MarkerCircle
        featureId={featureId}
        sizeAsText={sizeAsText}
        isHovered={isHovered}
        onMouseOut={onMouseOut}
        handleHoverMenu={handleHoverMenu}
      />
      <Dropdown>
        <Menu
          ref={menuRef}
          placement="top"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && isHovered && isPoint}
          onMouseLeave={onMouseOut}
          sx={{
            boxShadow: 'sm',
            flexGrow: 0,
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0.5rem',
              borderBottom: '1px solid #dadde9',

              '&:last-of-type': {
                borderBottom: 'none',
              },
            }}
          >
            <Box>
              <a
                href={`/profiles/${feature?.details?.content_type}/${feature?.details?.provider_id}`}
                target={sameTabRouting ? '_self' : '_blank'}
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  width: '100%',
                }}
              >
                <Typography
                  level="title-sm"
                  color="primary"
                  sx={{
                    textDecoration: 'none',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                    '&:hover': {
                      color: 'primary.700',
                    },
                  }}
                >
                  {feature?.details?.name}
                  <Icon icon={faExternalLinkAlt} size="sm" color="inherit" />
                </Typography>
              </a>
              <Typography level="body-xs">
                {feature?.details?.specialty}
              </Typography>
              <Typography level="body-xs">
                {feature?.details?.address_line_1}
              </Typography>
              <Typography level="body-xs">
                {feature?.details?.address_line_2}
              </Typography>
              <Typography level="body-xs">
                {feature?.details?.city}, {feature?.details?.state}
              </Typography>
              <Typography
                level="title-sm"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  addToList(feature?.details);
                }}
                sx={{
                  textDecoration: 'none',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.25rem',
                  '&:hover': {
                    cursor: 'pointer',
                    color: 'primary.700',
                  },
                }}
              >
                Add to List
              </Typography>
            </Box>
          </Box>
        </Menu>
      </Dropdown>
    </AdvancedMarker>
  );
};

export default FeatureMarker;
