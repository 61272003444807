import { Box, Typography } from '@mui/joy';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRouter } from 'next/router';
import { useLogging, useNotification } from 'src/context';
import { useDeleteMapping } from 'src/hooks';
import { useIntegrations } from 'src/modules/Settings/context';
import { titleCase } from 'src/utils';

const DeleteMappingSection = () => {
  const log = useLogging();
  const router = useRouter();
  const { id } = router.query as {
    id: string;
  };
  const { setNotification } = useNotification();
  const { mappings } = useIntegrations();
  const currentMapping = mappings.find(
    (mapping) => mapping.id.toString() === id
  );
  const { mutateAsync: deleteMapping } = useDeleteMapping();

  const onDelete = async () => {
    if (
      confirm(
        `Are you sure you want to delete mapping ${currentMapping.label}?`
      )
    ) {
      try {
        await deleteMapping(id);
        setNotification({
          title: 'Success',
          message: 'Mapping was deleted successfully.',
          type: 'success',
        });
        log.event(`Deleted Mapping`, {
          ...currentMapping,
        });
      } catch (err: any) {
        setNotification({
          title: 'Delete Failure',
          message: err?.cause?.response?.data?.error_message || err.message,
          type: 'error',
        });
        log.exception(`Error deleting mapping: ${err}`);
      }
    }
  };

  return (
    <Box sx={{ marginBottom: '5rem' }}>
      <Typography
        sx={{
          mt: 5,
          pt: 2,
          borderTop: `1px solid ${grey[200]}`,
        }}
        level="h3"
      >
        Delete Configuration
      </Typography>
      <Typography
        sx={{
          mt: 2,
          mb: 3,
        }}
      >
        Delete the &quot;{currentMapping?.label}&quot; mapping from your{' '}
        {titleCase(currentMapping?.crm)} integration. This action cannot be
        undone.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mb: 1 }}
        onClick={onDelete}
      >
        Delete Mapping
      </Button>
    </Box>
  );
};

export default DeleteMappingSection;
