import { Grid } from '@mui/joy';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ControlledCheckbox,
  ControlledInput,
  ControlledJoyCheckbox,
  ControlledSelect,
} from 'src/components';
import { ControlledCrmObjectSelect } from 'src/modules/Settings/components/ControlledCrmObjectSelect';
import { inputOptions } from 'src/modules/Settings/constants';
import { DynamicSelectOptionsForm } from 'src/modules/Settings/components/CRMMappings/Options/components';

const EditUserInput = ({ mapping }) => {
  const { watch } = useFormContext();

  const isThirdParty = mapping?.crm === 'THIRD_PARTY';

  const renderSubComponent = () => {
    if (!watch('input_type')) return null;
    const inputType = watch('input_type');
    switch (inputType) {
      case 'BOOLEAN': {
        return (
          <ControlledCheckbox
            name="extra.default_value"
            label="Default Value"
            size="small"
            rules={{ required: false }}
          />
        );
      }
      case 'SELECTOR': {
        return (
          <DynamicSelectOptionsForm
            name="extra.options"
            required={true}
            rules={{ required: true }}
          />
        );
      }
      case 'INTEGER': {
        return (
          <ControlledInput
            name="extra.default_value"
            label="Default Value"
            size="small"
            rules={{ required: false }}
            type="number"
          />
        );
      }
      case 'STRING': {
        return (
          <ControlledInput
            name="extra.default_value"
            label="Default Value"
            size="small"
            rules={{ required: false }}
          />
        );
      }
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <ControlledInput
          name="label"
          label="Label"
          size="small"
          variant="outlined"
          autoFocus={true}
          rules={{ required: true }}
          placeholder="e.g. MedScout HCP"
        />
      </Grid>
      <Grid xs={12}>
        {isThirdParty ? (
          <ControlledInput name="crm_api_code" label="CRM Property Name" />
        ) : (
          <ControlledCrmObjectSelect
            name="crm_api_code"
            label="CRM Property Name"
            size="small"
            placeholder="Select..."
            writableOnly
            crmType={mapping?.crm_object_type}
            integration={mapping?.crm}
            rules={{ required: true }}
          />
        )}
      </Grid>
      <Grid xs={12}>
        <ControlledSelect
          name="input_type"
          label="Input Type"
          size="small"
          rules={{ required: true }}
          options={inputOptions}
        />
      </Grid>
      <Grid xs={12}>{renderSubComponent()}</Grid>
      <Grid xs={12}>
        <ControlledJoyCheckbox
          size="sm"
          name="extra.required"
          label="Required"
        />
      </Grid>
      <Grid xs={12}>
        <ControlledJoyCheckbox size="sm" name="hidden" label="Hidden" />
      </Grid>
    </Grid>
  );
};

export default EditUserInput;
